import cn from 'classnames';
import * as React from 'react';

import { PlaceTypeModel } from '@/models/PlaceTypeModel';

import Typo, { TypoView } from '../Typo';

import s from './PlaceType.module.scss';

type PlaceTypeProps = {
  placeType: PlaceTypeModel;
  className?: string;
};

const PlaceType = ({ placeType, className }: PlaceTypeProps): React.ReactElement<PlaceTypeProps> => {
  return (
    <div className={cn(s['place-type'], className)}>
      {placeType.logo && (
        <div className={s['place-type__icon-container']} dangerouslySetInnerHTML={{ __html: placeType.logo }} />
      )}
      <Typo view={TypoView.textSmall} className={s['place-type__title']}>
        {placeType.label}
      </Typo>
    </div>
  );
};

export default PlaceType;
