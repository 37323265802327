import { BaseResponse } from '@kts-front/types';
import { noop } from '@kts-front/utils';

import { apiStore, apiUrls } from '@/api';
import { CrmRejectionInfoServer, LeadResultModalState } from '@/entities/lead';
import { CreateLeadResultType } from '@/entities/modal';
import { FixationFormDefaultValues, FixationFormModel } from '@/models/FixationFormModel';
import { InputModel } from '@/models/InputModel';
import { LeadModel } from '@/models/LeadModel';
import { LoadingStageModel } from '@/models/LoadingStageModel';
import { ToggleModel } from '@/models/ToggleModel';
import { ValueModel } from '@/models/ValueModel';
import { IRootStore } from '@/stores/global/RootStore';
import { ApiErrorCode, ApiErrorData, ApiErrorDataWithFields } from '@/types/meta';
import { Nullable } from '@/types/values';
import { validateNotEmpty } from '@/utils/validators';

import { LocalStore } from '../LocalStore';

export default class LeadCreationModalStore extends LocalStore {
  readonly clientId: InputModel<number | null>;
  readonly fixationForm: FixationFormModel;
  private readonly _isServerErrorModel = new ValueModel(false);
  readonly submitStage = new LoadingStageModel();
  readonly resultModalState = new ValueModel<Nullable<LeadResultModalState>>(null);
  readonly errorModalState = new ToggleModel(false);

  private _submitRequest = apiStore.createRequest({
    method: 'POST',
    url: apiUrls.leads.create,
  });

  private readonly _onClose: VoidFunction;
  private readonly _refetch: VoidFunction;
  private readonly _onOpen: VoidFunction;

  private readonly _rootStore: IRootStore;

  constructor({
    clientId,
    rootStore,
    onClose,
    refetch,
    onOpen,
    defaultValues,
  }: {
    clientId: number | null;
    rootStore: IRootStore;
    onClose: VoidFunction;
    refetch?: VoidFunction;
    onOpen: VoidFunction;
    defaultValues?: FixationFormDefaultValues;
  }) {
    super();
    this.clientId = new InputModel({
      name: 'client_id',
      caption: 'Выберите клиента',
      required: true,
      initialValue: clientId,
      validators: [validateNotEmpty()],
    });
    this.fixationForm = new FixationFormModel({ rootStore, defaultValues });
    this._onClose = onClose;
    this._refetch = refetch || noop;
    this._onOpen = onOpen;
    this._rootStore = rootStore;
  }

  get isServerError(): boolean {
    return this._isServerErrorModel.value;
  }

  get isFieldError(): boolean {
    return this.clientId.isError || this.fixationForm.isError;
  }

  handlePrimaryButtonClick = async (): Promise<BaseResponse> => {
    const isValidationError = this._validate();

    if (isValidationError) {
      return {
        isError: true,
      };
    }

    return this._postFormData();
  };

  resetServerError = (): void => {
    this._isServerErrorModel.change(false);
  };

  closeModal = (): void => {
    this._onClose();
    this._reset();
  };

  closeResultModal = (): void => {
    this.resultModalState.change(null);
  };

  handleSubmitResultModal = (): void => {
    this.closeResultModal();
    this._onOpen();
  };

  changeClient = (clientId: Nullable<number>): void => {
    this.clientId.change(clientId);

    if (!clientId) {
      return;
    }

    const client = this._rootStore.dictionariesStore.clients.getEntity(clientId);
    const inn = client?.inn ?? '';
    this.fixationForm.inn.change(inn);
    this.fixationForm.inn.resetError();
    this.fixationForm.inn.setDisabled(Boolean(inn));
  };

  destroy(): void {
    super.destroy();
    this.fixationForm.destroy();
  }

  private _validate(): boolean {
    const isClientFormError = this.clientId.validate();
    const isFixationFormError = this.fixationForm.validate();

    if (isClientFormError) {
      this.clientId.scrollToField();
      this.submitStage.error();

      return true;
    }

    if (isFixationFormError) {
      this.fixationForm.scrollToErrorField();
      this.submitStage.error();

      return true;
    }

    return false;
  }

  private async _postFormData(): Promise<BaseResponse> {
    this.submitStage.loading();

    const fixationFormJsonData = this.fixationForm.toJson();

    const response = await this._submitRequest.call({
      data: {
        client_id: this.clientId.value,
        ...fixationFormJsonData,
      },
    });

    if (!response.isError) {
      this.submitStage.success();
      this.closeModal();
      this._refetch();
      this.resultModalState.change({
        type: CreateLeadResultType.success,
      });

      return {
        isError: false,
      };
    }

    this.submitStage.error();

    this._errorHandle(response.data?.data || null);

    return {
      isError: true,
    };
  }

  private _errorHandle(
    responseData: ApiErrorDataWithFields | ApiErrorData<ApiErrorCode.nonuniqueBid, CrmRejectionInfoServer> | null,
  ): void {
    // Кейс, при котором сервер возвращает ошибку валидации полей формы
    if (
      (responseData?.code === ApiErrorCode.conflict || responseData?.code === ApiErrorCode.badRequest) &&
      responseData?.data?.fields
    ) {
      const fields = responseData.data.fields;
      Object.entries(fields).forEach(([fieldName, errorText]) => {
        switch (fieldName) {
          case 'project': {
            this.fixationForm.project.changeError(errorText || '');
            this.fixationForm.project.scrollToField();

            break;
          }

          case 'place_type': {
            this.fixationForm.placeType.changeError(errorText || '');
            this.fixationForm.placeType.scrollToField();

            break;
          }

          default: {
            this._isServerErrorModel.change(true);
          }
        }
      });

      this.errorModalState.open();

      return;
    }

    // Кейс, когда созданная заявка не уникальна
    if (responseData?.code === ApiErrorCode.nonuniqueBid) {
      this.closeModal();
      this._refetch();
      const crmRejectionInfo = responseData.data;

      if (!crmRejectionInfo) {
        this.resultModalState.change({
          type: CreateLeadResultType.refused,
        });

        return;
      }

      this.resultModalState.change({
        type: CreateLeadResultType.refusedWithInfo,
        data: {
          crmRejectionInfo: LeadModel.normalizeCrmRejectionInfo(crmRejectionInfo),
        },
      });

      return;
    }

    this._isServerErrorModel.change(true);
  }

  private _reset(): void {
    this.clientId.reset();
    this.fixationForm.reset();
    this.resetServerError();
  }
}
