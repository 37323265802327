import { RouteSegment } from './constants';

export const routerUrls = {
  root: '/',
  main: {
    mask: `/${RouteSegment.main}`,
    create: () => `/${RouteSegment.main}`,
  },
  leadList: {
    mask: `/${RouteSegment.leads}`,
    create: () => `/${RouteSegment.leads}`,
  },
  clientList: {
    mask: `/${RouteSegment.clients}`,
    create: () => `/${RouteSegment.clients}`,
  },
  clientDetail: {
    mask: `/${RouteSegment.client}/:clientId`,
    create: (clientId: number | string) => `/${RouteSegment.client}/${clientId}`,
  },
  employeeList: {
    mask: `/${RouteSegment.employees}`,
    create: () => `/${RouteSegment.employees}`,
  },
  agencyProfile: {
    mask: `/${RouteSegment.agency}`,
    create: () => `/${RouteSegment.agency}`,
  },
  booking: {
    mask: `/${RouteSegment.booking}`,
    create: () => `/${RouteSegment.booking}`,
  },
};
