import { BaseClientServer, IBaseClient } from '@/entities/client';
import { getFullName, getFullNameWithPatronymic } from '@/utils/getFullName';

import { PhoneNumber } from './PhoneNumber';

export class BaseClientModel implements IBaseClient {
  readonly id;
  readonly manager;
  readonly firstName;
  readonly lastName;
  readonly patronymic;
  readonly phone;
  readonly email;
  readonly subAgent;
  readonly inn;
  readonly rawPhone;

  constructor({ id, manager, firstName, lastName, phone, email, subAgent, inn, patronymic, rawPhone }: IBaseClient) {
    this.id = id;
    this.manager = manager;
    this.firstName = firstName;
    this.lastName = lastName;
    this.patronymic = patronymic;
    this.phone = PhoneNumber.format(phone);
    this.email = email;
    this.subAgent = subAgent;
    this.inn = inn;
    this.rawPhone = rawPhone;
  }

  get clientFullNameAndPhone(): string {
    return `${this.clientFullName}, ${this.phone}`;
  }

  get clientFullName(): string {
    return getFullName({ firstName: this.firstName, lastName: this.lastName });
  }
  get clientFullNameWithPatronymic(): string {
    return getFullNameWithPatronymic(this);
  }

  get shortClientFullName(): string {
    return getFullName({ firstName: this.firstName, lastName: this.lastName, reversed: false, short: true });
  }

  get managerFullName(): string {
    return getFullName({
      firstName: this.manager.firstName,
      lastName: this.manager.lastName,
    });
  }

  get shortManagerFullName(): string {
    return getFullName({
      firstName: this.manager.firstName,
      lastName: this.manager.lastName,
      reversed: false,
      short: true,
    });
  }

  static getManager(manager: BaseClientServer['manager']): BaseClientModel['manager'] {
    return {
      id: manager.id,
      firstName: manager.first_name,
      lastName: manager.last_name ?? '',
    };
  }

  static getSubAgent(subAgent: BaseClientServer['sub_agent']): BaseClientModel['subAgent'] {
    return subAgent?.name && subAgent?.phone
      ? {
          name: subAgent.name,
          phone: subAgent.phone,
        }
      : null;
  }

  static fromJson(raw: BaseClientServer): BaseClientModel {
    const phone = PhoneNumber.fromJson(raw.phone);
    const manager = BaseClientModel.getManager(raw.manager);
    const subAgent = BaseClientModel.getSubAgent(raw.sub_agent);

    return new BaseClientModel({
      id: raw.id,
      manager,
      firstName: raw.first_name,
      lastName: raw.last_name,
      patronymic: raw.patronymic,
      phone,
      email: raw.email ?? '',
      subAgent,
      inn: raw.inn,
      rawPhone: raw.phone,
    });
  }
}
