import { SelectOption } from '@/types/values';

export enum PlaceTypeEnum {
  living = 'living',
  commercial = 'commercial',
  parking = 'parking',
  storage = 'storage',
}

export interface IProject extends SelectOption<number> {
  color: string;
  placeTypes: Set<PlaceTypeEnum>;
}

export interface IPlaceType extends SelectOption<string> {
  logo: string | null;
}

export interface ISource {
  id: number;
  slug: string;
  title: string;
}
