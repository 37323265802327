import cn from 'classnames';
import * as React from 'react';
import InfiniteScroll from 'react-infinite-scroller';

import Loader from '@/components/Loader';
import { LoadingStage } from '@/types/meta';

import { DialogContentProps } from '../DialogStub';
import StubWrapper from '../StubWrapper';

import ScrollUpBottom from './ScrollUpBottom';

import s from './InfiniteList.module.scss';

type InfiniteListItemProps<T> = {
  item: T;
};

interface IItem {
  id: number;
}

type Props<T extends IItem> = {
  className?: string;
  scrollUpButtonCls?: string;
  loadMore: () => void;
  hasMore: boolean;
  containerRef: React.RefObject<HTMLDivElement | null>;
  entities: T[];
  ItemComponent: React.ComponentType<InfiniteListItemProps<T>>;
  loadingStage: LoadingStage;
  hasSelectedFiltersOrSearch?: boolean;
  refetch: () => void;
  resetFilters?: () => void;
  emptyState: DialogContentProps;
};

const InfiniteList = <T extends IItem>({
  className,
  scrollUpButtonCls,
  loadMore,
  hasMore,
  containerRef,
  entities,
  ItemComponent,
  hasSelectedFiltersOrSearch,
  loadingStage,
  refetch,
  resetFilters,
  emptyState,
}: Props<T>): React.ReactElement => {
  const getScrollParent = React.useCallback(() => containerRef.current, []);

  const isError = loadingStage === LoadingStage.error;
  const isLoading = loadingStage === LoadingStage.loading;
  const isEmpty = !entities.length && loadingStage === LoadingStage.success;

  return (
    <StubWrapper
      isError={isError}
      isEmpty={isEmpty}
      hasSelectedFiltersOrSearch={hasSelectedFiltersOrSearch}
      refetch={refetch}
      resetFilters={resetFilters}
      emptyState={emptyState}
    >
      <InfiniteScroll
        className={className}
        loadMore={loadMore}
        hasMore={hasMore}
        useWindow={false}
        getScrollParent={getScrollParent}
        initialLoad={false}
      >
        <>
          {entities.map((item) => (
            <ItemComponent key={item.id} item={item} />
          ))}
          {isLoading && <Loader className={cn(s.loader, !entities.length && s.loader_init)} />}
        </>
      </InfiniteScroll>
      <ScrollUpBottom scrollUpButtonCls={scrollUpButtonCls} containerRef={containerRef} />
    </StubWrapper>
  );
};

export default InfiniteList;
