import { ClientServer, IClient } from '@/entities/client';

import { BaseClientModel } from './BaseClientModel';
import { PhoneNumber } from './PhoneNumber';

export class ClientModel extends BaseClientModel implements IClient {
  readonly placeTypes;
  readonly projects;
  readonly status;

  constructor({ placeTypes, projects, status, ...props }: IClient) {
    super(props);
    this.placeTypes = placeTypes;
    this.projects = projects;
    this.status = status;
  }

  static fromJson(raw: ClientServer): ClientModel {
    const phone = PhoneNumber.fromJson(raw.phone);
    const manager = BaseClientModel.getManager(raw.manager);
    const subAgent = BaseClientModel.getSubAgent(raw.sub_agent);

    return new ClientModel({
      id: raw.id,
      manager,
      firstName: raw.first_name,
      lastName: raw.last_name,
      patronymic: raw.patronymic,
      phone,
      email: raw.email ?? '',
      placeTypes: raw.place_types,
      projects: raw.projects,
      status: raw.status,
      subAgent,
      inn: raw.inn,
      rawPhone: raw.phone,
    });
  }
}
