import { ClientModel } from '@/models/ClientModel';
import { ListModel } from '@/models/ListModel';
import { PlaceTypeModel } from '@/models/PlaceTypeModel';
import { ProjectModel } from '@/models/ProjectModel';
import { SourceModel } from '@/models/SourceModel';
import { SelectOption } from '@/types/values';

export enum DictionaryFields {
  employees = 'employees',
  placeTypes = 'placeTypes',
  projects = 'projects',
  bookingProjects = 'bookingProjects',
  sources = 'sources',
  clients = 'clients',
}

export enum DictionaryLoadFields {
  loadEmployees = 'loadEmployees',
  loadProjects = 'loadProjects',
  loadBookingProjects = 'loadBookingProjects',
  loadPlaceTypes = 'loadPlaceTypes',
  loadSources = 'loadSources',
  loadClients = 'loadClients',
}

export interface IDictionariesStore {
  [DictionaryFields.employees]: ListModel<SelectOption<number>, number>;
  [DictionaryFields.projects]: ListModel<ProjectModel, number>;
  [DictionaryFields.bookingProjects]: ListModel<ProjectModel, number>;
  [DictionaryFields.placeTypes]: ListModel<PlaceTypeModel, string>;
  [DictionaryFields.sources]: ListModel<SourceModel, string>;
  [DictionaryFields.clients]: ListModel<ClientModel, number>;
}
