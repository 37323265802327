import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { PlaceType } from '@/components/PlaceType';
import { ILead } from '@/entities/lead';
import { useRootStore } from '@/stores/global/RootStore';

const LeadPlaceType: React.FC<{ item: ILead }> = ({ item }) => {
  const { dictionariesStore } = useRootStore();
  const placeType = dictionariesStore.placeTypes.getEntity(item.placeType);

  return placeType && <PlaceType placeType={placeType} />;
};

export default observer(LeadPlaceType);
