const getUserPrefix = (endpoint: string): string => `/user/${endpoint}`;
const getEmployeePrefix = (endpoint: string): string => `/employee/${endpoint}`;
const getClientPrefix = (endpoint: string): string => `/client/${endpoint}`;
const getIntegrationPrefix = (endpoint: string): string => `/integration/${endpoint}`;
const getNotificationsPrefix = (endpoint: string): string => `/notifications/${endpoint}`;
const getBookingPrefix = (endpoint: string): string => `/booking/${endpoint}`;
const getBankPrefix = (endpoint: string): string => `/bank_details/${endpoint}`;

export const apiUrls = {
  dictionaries: {
    projects: '/project/list',
    placeTypes: '/placetype/list',
  },
  sources: {
    list: '/source/list',
  },
  leads: {
    list: '/bid/list',
    create: '/v2/bid/create',
    item: {
      get: (id: number) => `/bid/${id}`,
    },
  },
  client: {
    edit: getClientPrefix('edit'),
    item: {
      get: (id: number) => `/client/${id}`,
      bids: (id: number) => `/client/${id}/bids`,
    },
    list: getClientPrefix('list'),
    create: getClientPrefix('create'),
  },
  user: {
    current: getUserPrefix('current'),
    logout: getUserPrefix('logout'),
  },
  registration: '/registration',
  employee: {
    list: getEmployeePrefix('list'),
    edit: getEmployeePrefix('edit'),
    block: getEmployeePrefix('block'),
    unblock: getEmployeePrefix('unblock'),
    create: getEmployeePrefix('create'),
  },
  integration: {
    ispring: getIntegrationPrefix('ispring/get_url'),
  },

  notifications: {
    list: getNotificationsPrefix('list'),
    getPublicKey: getNotificationsPrefix('get_public_key'),
    subscribe: getNotificationsPrefix('subscribe'),
    removeSubscription: getNotificationsPrefix('remove_subscription'),
  },

  booking: {
    client: getBookingPrefix('client/list'),
    create: '/booking',
    // api нужен, тк это ссылка для скачивания файла
    offer: (bookingId: number) => `/api/booking/offer?booking_id=${bookingId}`,
  },

  agency: {
    editCommonInfo: '/agency',
  },

  bank: {
    bankDetailsCreate: getBankPrefix('create'),
    bankDetailsEdit: getBankPrefix('edit'),
  },

  flats: {
    list: (projectId: number) => `/flats/${projectId}`,
  },
};
