import { routerUrls, RouteSegment } from '@/configs/routes';

type ButtonPageTitleConfig = {
  title: string;
  backUrl: string;
};

export const PageTitles: Partial<Record<RouteSegment, string>> = {
  [RouteSegment.main]: 'Личный кабинет партнера',
  [RouteSegment.leads]: 'Фиксация клиентов',
  [RouteSegment.client]: 'Фиксация клиентов',
  [RouteSegment.clients]: 'Фиксация клиентов',
  [RouteSegment.employees]: 'Управление доступами',
  [RouteSegment.agency]: 'Фиксация клиентов',
  [RouteSegment.booking]: 'Бронирование лотов',
};

export const ButtonPageTitles: Partial<Record<RouteSegment, ButtonPageTitleConfig>> = {
  [RouteSegment.client]: {
    title: 'Клиенты',
    backUrl: routerUrls.clientList.create(),
  },
  [RouteSegment.clients]: {
    title: 'Главная',
    backUrl: routerUrls.main.create(),
  },
  [RouteSegment.leads]: {
    title: 'Главная',
    backUrl: routerUrls.main.create(),
  },
  [RouteSegment.employees]: {
    title: 'Главная',
    backUrl: routerUrls.main.create(),
  },
  [RouteSegment.booking]: {
    title: 'Главная',
    backUrl: routerUrls.main.create(),
  },
};
