import { BaseResponse } from '@kts-front/types';

import { apiStore, apiUrls } from '@/api';
import { ClientListResponse } from '@/entities/client';
import { PlaceTypeServer, ProjectServer, SourceServer } from '@/entities/dictionaries';
import { EmployeeListResponse } from '@/entities/employee';
import { ClientModel } from '@/models/ClientModel';
import { EmployeeModel } from '@/models/EmployeeModel';
import { ListModel } from '@/models/ListModel';
import { PlaceTypeModel } from '@/models/PlaceTypeModel';
import { ProjectModel } from '@/models/ProjectModel';
import { SourceModel } from '@/models/SourceModel';
import { SelectOption } from '@/types/values';

import { DictionaryFields, DictionaryLoadFields, IDictionariesStore } from './types';

export class DictionariesStore implements IDictionariesStore {
  readonly [DictionaryFields.employees] = new ListModel<SelectOption<number>, number>();
  readonly [DictionaryFields.projects] = new ListModel<ProjectModel, number>();
  readonly [DictionaryFields.bookingProjects] = new ListModel<ProjectModel, number>();
  readonly [DictionaryFields.placeTypes] = new ListModel<PlaceTypeModel, string>();
  readonly [DictionaryFields.sources] = new ListModel<SourceModel, string>();
  readonly [DictionaryFields.clients] = new ListModel<ClientModel, number>();

  private readonly _projectsRequest = apiStore.createRequest<{ projects: ProjectServer[] }>({
    method: 'GET',
    url: apiUrls.dictionaries.projects,
  });

  private readonly _placeTypesRequest = apiStore.createRequest<{ places: PlaceTypeServer[] }>({
    method: 'GET',
    url: apiUrls.dictionaries.placeTypes,
  });

  private readonly _employeesRequest = apiStore.createRequest<EmployeeListResponse>({
    method: 'GET',
    url: apiUrls.employee.list,
  });

  private readonly _sourcesRequest = apiStore.createRequest<{ sources: SourceServer[] }>({
    method: 'GET',
    url: apiUrls.sources.list,
  });

  private readonly _clientsRequest = apiStore.createRequest<ClientListResponse>({
    method: 'POST',
    url: apiUrls.client.list,
  });

  async [DictionaryLoadFields.loadProjects](): Promise<BaseResponse> {
    if (this.projects.loadingStage.isLoading) {
      return {
        isError: true,
      };
    }

    this.projects.loadingStage.loading();

    const response = await this._projectsRequest.call();

    if (response.isError) {
      this.projects.loadingStage.error();

      return {
        isError: true,
      };
    }

    this.projects.fillByRawData(
      response.data.projects,
      (rawItem) => ({
        entity: ProjectModel.fromJson(rawItem),
        key: rawItem.id,
      }),
      true,
    );

    this.projects.loadingStage.success();

    return {
      isError: false,
    };
  }

  async [DictionaryLoadFields.loadBookingProjects](): Promise<BaseResponse> {
    if (this.bookingProjects.loadingStage.isLoading) {
      return {
        isError: true,
      };
    }

    this.bookingProjects.loadingStage.loading();

    const response = await this._projectsRequest.call({
      params: {
        is_booking: true,
      },
    });

    if (response.isError) {
      this.bookingProjects.loadingStage.error();

      return {
        isError: true,
      };
    }

    this.bookingProjects.fillByRawData(
      response.data.projects,
      (rawItem) => ({
        entity: ProjectModel.fromJson(rawItem),
        key: rawItem.id,
      }),
      true,
    );

    this.bookingProjects.loadingStage.success();

    return {
      isError: false,
    };
  }

  async [DictionaryLoadFields.loadPlaceTypes](): Promise<BaseResponse> {
    if (this.placeTypes.loadingStage.isLoading) {
      return {
        isError: true,
      };
    }

    this.placeTypes.loadingStage.loading();

    const response = await this._placeTypesRequest.call();

    if (response.isError) {
      this.placeTypes.loadingStage.error();

      return {
        isError: true,
      };
    }

    this.placeTypes.fillByRawData(
      response.data.places,
      (rawItem) => ({
        entity: PlaceTypeModel.fromJson(rawItem),
        key: rawItem.slug,
      }),
      true,
    );

    this.placeTypes.loadingStage.success();

    return {
      isError: false,
    };
  }

  async [DictionaryLoadFields.loadEmployees](): Promise<BaseResponse> {
    if (this.employees.loadingStage.isLoading) {
      return {
        isError: true,
      };
    }

    this.employees.loadingStage.loading();

    const response = await this._employeesRequest.call({
      params: {
        offset: 0,
        limit: 10000,
      },
    });

    if (response.isError) {
      this.employees.loadingStage.error();

      return {
        isError: true,
      };
    }

    this.employees.fillByRawData(
      response.data.users,
      (rawItem) => ({
        entity: EmployeeModel.fromJson({ data: rawItem }),
        key: rawItem.id,
      }),
      true,
    );

    this.employees.loadingStage.success();

    return {
      isError: false,
    };
  }

  async [DictionaryLoadFields.loadSources](): Promise<BaseResponse> {
    if (this.sources.loadingStage.isLoading) {
      return {
        isError: true,
      };
    }

    this.sources.loadingStage.loading();

    const response = await this._sourcesRequest.call();

    if (response.isError) {
      this.sources.loadingStage.error();

      return {
        isError: true,
      };
    }

    this.sources.fillByRawData(
      response.data.sources,
      (rawItem) => ({
        entity: SourceModel.fromJson(rawItem),
        key: rawItem.slug,
      }),
      true,
    );

    this.sources.loadingStage.success();

    return {
      isError: false,
    };
  }

  async [DictionaryLoadFields.loadClients](): Promise<BaseResponse> {
    if (this.clients.loadingStage.isLoading) {
      return {
        isError: true,
      };
    }

    this.clients.loadingStage.loading();

    const response = await this._clientsRequest.call({
      data: {
        pagination: {
          offset: 0,
          limit: 10000,
        },
        filters: {},
      },
    });

    if (response.isError) {
      this.clients.loadingStage.error();

      return {
        isError: true,
      };
    }

    this.clients.fillByRawData(
      response.data.clients,
      (rawItem) => ({
        entity: ClientModel.fromJson(rawItem),
        key: rawItem.id,
      }),
      true,
    );

    this.clients.loadingStage.success();

    return {
      isError: false,
    };
  }
}
